import * as Types from "../types";

type ContentScriptContext = {
  req: Types.ContentScript.Request;
  _sender: chrome.runtime.MessageSender;
};

type RequestHandler = (ctx: ContentScriptContext) => Types.ContentScript.Response | undefined;

const getDom: RequestHandler = () => {
  const parsed = document.body.outerHTML;
  return { document: parsed };
};

const requestHandler = (
  req: Types.ContentScript.Request,
  _sender: chrome.runtime.MessageSender,
  sendResponse: (res: Types.ContentScript.Response) => void,
): boolean => {
  const ctx = { req, _sender };
  let response: Types.ContentScript.Response | undefined;
  switch (req.type) {
    case Types.ContentScript.RequestType.GetDom:
      response = getDom(ctx);
      break;
    default:
      return false;
  }

  if (response != undefined) sendResponse(response);
  return true;
};

chrome.runtime.onMessage.addListener(requestHandler);
